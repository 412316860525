@import "../SCSS/Mixins.scss";
@import url('https: //fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: "Inter", sans-serif;
  @include fontSize(40px);
  line-height: 60px;
  font-weight: 700;
}
h3 {
  font-family: "Inter", sans-serif;
  @include fontSize(32px);
  line-height: 38px;
  font-weight: 700;
}
h4 {
  font-family: "Inter", sans-serif;
  @include fontSize(24px);
  line-height: 30px;
  font-weight: 700;
}
h5 {
  font-family: "Inter", sans-serif;
  @include fontSize(20px);
  line-height: 24px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    @include fontSize(18px);
    line-height: 22px;
  }
}
h6 {
  font-family: "Inter", sans-serif;
  @include fontSize(18px);
  line-height: 29px;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    @include fontSize(16px);
    line-height: 20px;
  }
}

p {
  @include fontSize(14px);
  line-height: 18px;
  font-weight: 400;
  color: $darkgrey;
  @media only screen and (max-width: 768px) {
    @include fontSize(12px);
  }
}
a {
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}
button {
  font-family: "Inter", sans-serif;
}
img {
  vertical-align: bottom;
}
.MuiGrid2-container {
  max-width: 1400px;
}

.react-multi-carousel-list {
  padding-bottom: 40px;
  .react-multiple-carousel__arrow {
    background: $white;
    border: 1px solid rgba($black, 0.2);
    border-radius: 4px;
    top: auto;
    bottom: 0px;
    min-height: 32px;
    min-width: 32px;
    z-index: 800;
    &:hover {
      background: $white;
    }
    &:before {
      color: $black;
      font-size: 12px;
      font-weight: bold;
    }
    &:disabled {
      background: $white;
      opacity: 0.5;
    }

    &.react-multiple-carousel__arrow--left {
      left: calc(50% - 40px);
    }
    &.react-multiple-carousel__arrow--right {
      right: calc(50% - 40px);
    }
  }
}
.mheaderSlider {
  position: relative;
  .react-multi-carousel-list {
    padding-bottom: 0px;
  }
}
.headerSlider {
  .react-multi-carousel-list {
    &:hover {
      transition: all ease-in-out 0.5s;
      .react-multiple-carousel__arrow {
        display: block;
      }
    }

    .react-multiple-carousel__arrow {
      border: none;
      top: auto;
      bottom: auto;
      min-height: 62px;
      min-width: 42px;
      z-index: 800;
      display: none;
      box-shadow: 0px 4px 12px rgba($black, 0.3);
      // opacity: 0.7;

      &:before {
        color: $black;
        font-size: 16px;
        font-weight: bold;
      }

      &.react-multiple-carousel__arrow--left {
        left: 12px;
      }

      &.react-multiple-carousel__arrow--right {
        right: 12px;
      }
    }
  }
}
.stickyGrid {
  position: sticky;
  top: 86px;
  z-index: 900;
  background-color: $white;
  @media only screen and (max-width: 768px) {
    top: 36px;
    padding-bottom: 8px;
  }
}

.imgEffect {
  transition: all ease-in-out 0.2s;
  &:hover {
    // transform: scale(1.2);
    // box-shadow: 0px 4px 12px rgba($black, 0.5);
  }
}

.photoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px;
  margin-right: -12px;
  grid-template-areas:
    "col1 col3"
    "col2 col3"
    "col4 col5"
    "col6 col6";
  width: calc(100% + 12px);
  @media only screen and (max-width: 768px) {
    gap: 12px;
    width: 100%;
    margin-right: 0px;
  }
}
.oneHalf,
.oneHalf2,
.oneHalfThird,
.halfCol,
.halfCol2,
.fullWidthCol {
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  padding: 12px;
  @media only screen and (max-width: 768px) {
    padding: 0px;
  }
  img {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    &:hover {
      filter: brightness(70%);
    }
  }
}
.oneHalf {
  grid-area: col1;
}
.oneHalf2 {
  grid-area: col2;
}
.oneHalfThird {
  grid-area: col3;
}
.halfCol {
  grid-area: col4;
}
.halfCol2 {
  grid-area: col5;
}
.fullWidthCol {
  grid-area: col6;
  max-height: 500px;
  @media only screen and (max-width: 768px) {
    max-height: 300px;
    margin-top: -12px;
  }
}

.facilities {
  .facility {
    &:nth-child(3) {
      cursor: pointer;
      &:hover {
        p {
          color: #e7594d;
          position: relative;
        }
      }
      div {
        height: 80px;
        width: 80px;
        box-shadow: 0 0 0 0 #f78278;
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        @media (max-width: 899px) {
          height: 50px;
          width: 50px;
        }
        &:hover {
          background: #f7d7d4;
        }

        @keyframes pulse {
          to {
            box-shadow: 0 0 0 16px rgba(255, 232, 160, 0);
          }
        }

        // Safari and Chrome specific wiggle animation
        @-webkit-keyframes wiggle {
          0% {
            -webkit-transform: rotate(15deg);
          }
          50% {
            -webkit-transform: rotate(-15deg);
          }
          100% {
            -webkit-transform: rotate(15deg);
          }
        }

        // Firefox specific wiggle animation
        @-moz-keyframes wiggle {
          0% {
            -moz-transform: rotate(15deg);
          }
          50% {
            -moz-transform: rotate(-15deg);
          }
          100% {
            -moz-transform: rotate(15deg);
          }
        }

        // Standard wiggle animation for other browsers
        @keyframes wiggle {
          0% {
            transform: rotate(15deg);
          }
          50% {
            transform: rotate(-15deg);
          }
          100% {
            transform: rotate(15deg);
          }
        }
      }
    }
  }
}

.datepicker {
  background: $white;
  position: relative;
  .MuiTextField-root {
    width: 100%;
  }
}
.error {
  position: absolute;
  bottom: -16px;
  right: 0px;
  font-size: 12px;
  color: #e7594d;
}
